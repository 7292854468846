<template>
  <div class="page-content">

    <v-row v-if="!showEmptyState">
      <v-col cols="12" sm="6" lg="4">
        <v-skeleton-loader v-if="loadingContacts"
                           type="article"
                           height="300"/>
        <!-- contacts card -->
        <content-card v-else-if="contacts?.length > 0"
                      :title="$t('support-page.contacts.title').toString()"
                      icon="perm_contact_calendar"
                      color="primary">
          <template v-slot:content>
            <v-list subheader class="py-0 tileBackground">
              <v-list-item v-for="contact in contacts"
                           :key="contact.id"
                           class="list-item straight px-5"
                           link
                           @click="openContact(contact)">
                <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                                   v-text="contact.title"/>
                <v-list-item-action>
                  <v-icon color="primary">chevron_right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </content-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4">
        <v-skeleton-loader v-if="loadingForms"
                           type="article"
                           height="300"/>
        <!-- forms card -->
        <content-card v-else-if="forms?.length > 0 || hubspotElements?.length > 0"
                      :title="$t('support-page.forms.title').toString()"
                      icon="feed"
                      color="primary">
          <template v-slot:content>
            <v-list subheader class="py-0 tileBackground">
              <v-list-item v-for="form in forms"
                           :key="form.id"
                           class="list-item straight px-5"
                           link
                           @click="openForm(form)">
                <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                                   v-text="form.subject"/>
                <v-list-item-action>
                  <v-icon color="primary">chevron_right</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-list-item v-for="hubspotElement in hubspotElements"
                           :key="hubspotElement.id"
                           class="list-item straight px-5"
                           link
                           @click="openHubspotElement(hubspotElement)">
                <v-list-item-title class="font-size-02 primary--text font-weight-bold"
                                   v-text="hubspotElement.name"/>
                <v-list-item-action>
                  <v-icon color="primary">chevron_right</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </template>
        </content-card>
      </v-col>
    </v-row>

    <!-- Emptystate -->
    <div v-else>
      <empty-state
          :empty-state-title="$t('support-page.emptyState.title').toString()"
          :empty-state-text="$t('support-page.emptyState.text').toString()">
        <template v-slot:illustration>
          <SupportEmptyStateIllustration
              :fill-primary="'var(--v-primary-base)'"
              :fill-secondary="'var(--v-secondary-base)'"
          />
        </template>
      </empty-state>
    </div>

  </div>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import EmptyState from "@/templates/components/emptyStates/EmptyState";
import SupportEmptyStateIllustration from "@/templates/components/emptyStates/svg/SupportEmptyStateIllustration";
import config from "@/config/config.app.json";
import requestHelper from "@/scripts/requestHelper";

export default {
  name: "SupportPage",

  components: {ContentCard, EmptyState, SupportEmptyStateIllustration},

  data() {
    return {
      refreshRate: config.dataRefreshRate,
      loadingContacts: false,
      loadingForms: false,
      contacts: [],
      forms: [],
      hubspotElements: []
    }
  },

  methods: {
    /**
     * gets matching contacts from the API
     */
    getContactData(showLoader) {
      if (showLoader) {
        this.loadingContacts = true
      }

      let self = this
      this.$rhRequest.sendGet({
        endpoint: 'building-contact/get-contacts'
      }, function (response) {
        self.contacts = response?.data?.data
        self.loadingContacts = false
      }, function (error) {
        console.error(error)
        self.loadingContacts = false
      })
    },

    /**
     * gets matching forms from the API
     */
    getFormData(showLoader) {
      if (showLoader) {
        this.loadingForms = true
      }
      let self = this

      this.$rhRequest.sendGet({
        endpoint: 'content-service/get-forms'
      }, function (response) {
        self.forms = response?.data?.data
        self.getHubspotElements()
        self.loadingForms = false
      }, function (error) {
        console.error(error)
        // empty list if not found is returned
        if (error.response && error.response.status === 404) {
          self.formList = []
        }
        self.getHubspotElements()
        self.loadingForms = false
      })
    },

    /**
     * This method requests the hubspot forms and calendars from the backend
     */
    getHubspotElements() {
      let self = this
      this.$rhRequest.sendGet({
        endpoint: 'content-service/get-hubspot-elements'
      }, function (response) {
        self.hubspotElements = response?.data?.data
      }, function (error) {
        console.error(error)
        // empty list if not found is returned
        if (error.response && error.response.status === 404) {
          self.hubspotElements = []
        }
      })
    },

    /**
     * opens a fullscreen overlay with contact details
     *
     * @param contact
     */
    openContact(contact) {
      this.$root.bisadialog.toggle('contact', true, contact)
    },

    /**
     * opens a fullscreen overlay with a request form
     *
     * @param form
     */
    openForm(form) {
      // show capture image button only on android devices
      // reason is, if file selection gets opened on ios device, the option "take picture" can't be disabled,
      // therefore the button "take picture" is redundant for ios devices
      let showCaptureButton = false
      if (window?.cordova?.platformId.toLowerCase() === 'android') {
        showCaptureButton = true
      }

      this.$root.bisadialog.toggle('form', true, {form: form, showCaptureButton: showCaptureButton})
    },

    /**
     * opens a fullscreen overlay for a hubspot form or calendar
     *
     * @param data
     */
    openHubspotElement(data) {
      let key = null
      if (data.formId) {
        key = 'hubspotForm'
      } else if (data.calendarUrl) {
        key = 'hubspotCalendar'
      }
      this.$root.bisadialog.toggle(key, true, data)
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getContactData(false)
        this.getFormData(false)
      }, this.refreshRate)
    },
  },

  mounted() {
    this.loadingContacts = true
    requestHelper.startDelayedRequest(
        () => this.getContactData(true),
        () => this.updateData()
    )
    this.loadingForms = true
    requestHelper.startDelayedRequest(
        () => this.getFormData(true)
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },

  computed: {
    showEmptyState() {
      return (!this.loadingForms || !this.loadingContacts) && this.hasNoContent
    },
    hasNoContent() {
      return !this.forms?.length > 0 && !this.contacts?.length > 0
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/support-page.scss';
</style>
