import Vue from 'vue'
import config from '@/config/config.app.json'
import Router from 'vue-router'
import CustomRoutes from "@/config/customRoutes"

import HomePage from '@/templates/pages/living/HomePage.vue'
import EnergyPage from '@/templates/pages/living/EnergyPage.vue'
import EMobilityPage from '@/templates/pages/living/EMobilityPage.vue'
import InboxPage from '@/templates/pages/living/InboxPage'
import SupportPage from '@/templates/pages/living/SupportPage.vue'
import DocumentsPage from "@/templates/pages/living/DocumentsPage"
import WaterControlPage from '@/templates/pages/living/WaterControlPage.vue'
import LoginPage from '@/templates/pages/entry/LoginPage.vue'
import WelcomePage from '@/templates/pages/entry/WelcomePage.vue'
import AccountDeletion from '@/templates/pages/entry/AccountDeletionPage.vue'
import ProfilePage from "@/templates/pages/profile/ProfilePage"
import ResetPassword from "@/templates/pages/entry/ResetPassword"
import RegistrationSelection from '@/templates/pages/entry/RegistrationSelection'
import RegistrationCode from "@/templates/pages/entry/RegistrationCode"
import RegistrationUser from "@/templates/pages/entry/RegistrationUser"
import RegistrationEmail from "@/templates/pages/entry/RegistrationEmail"
import SmarthomePage from "@/templates/pages/living/SmarthomePage"
import UserManagementPage from "@/templates/pages/profile/UserManagementPage"
import OffersPage from "@/templates/pages/living/OffersPage"

Vue.use(Router)

const defaultRoutes = [
  {
    path: '*',
    redirect: 'home'
  },
  {
    path: '/',
    redirect: 'home'
  },
  {
    path: '',
    redirect: 'home'
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: WelcomePage
  },
  {
    path: '/accountDeletion',
    name: 'accountDeletion',
    component: AccountDeletion
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/registration',
    name: 'registration',
    component: RegistrationSelection
  },
  {
    path: '/registrationEmail',
    name: 'registrationEmail',
    component: RegistrationEmail
  },
  {
    path: '/registrationUser',
    name: 'registrationUser',
    component: RegistrationUser
  },
  {
    path: '/registrationCode',
    name: 'registrationCode',
    component: RegistrationCode
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      topNavLogoColor: 'primary',
      showBackArrow: config.clientKey !== 'habilio',
      backRoute: config.homeTopNavOrder.split(',')[0],
      bottomNavigationActive: 'profile'
    },
    beforeEnter: requireAuth
  },
  {
    path: '/userManagement',
    name: 'userManagement',
    component: UserManagementPage,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      topNavLogoColor: 'primary',
      showBackArrow: true,
      backRoute: 'profile',
      bottomNavigationActive: 'profile'
    },
    beforeEnter: canInviteAdditionalUser
  },
  {
    path: '/home',
    name: 'home',
    component: HomePage,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      showTopNavTabs: true,
      bottomNavigationActive: 'home'
    },
    beforeEnter: canAccessMainRoute
  },
  {
    path: '/energy',
    name: 'energy',
    component: EnergyPage,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      showTopNavTabs: true,
      bottomNavigationActive: 'home'
    },
    beforeEnter: canAccessMainRoute
  },
  {
    path: '/emobility',
    alias: '/enodeRedirect',
    name: 'emobility',
    component: EMobilityPage,
    params: true,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      showTopNavTabs: true,
      bottomNavigationActive: 'home'
    },
    beforeEnter: canAccessMainRoute
  },
  {
    path: '/inbox',
    name: 'inbox',
    component: InboxPage,
    params: true,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      showTopNavTabs: true,
      bottomNavigationActive: 'home'
    },
    beforeEnter: canAccessMainRoute
  },
  {
    path: '/documents',
    name: 'documents',
    component: DocumentsPage,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      showTopNavTabs: true,
      bottomNavigationActive: 'home'
    },
    beforeEnter: canAccessMainRoute
  },
  {
    path: '/watercontrol',
    name: 'watercontrol',
    component: WaterControlPage,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      showTopNavTabs: true,
      bottomNavigationActive: 'home'
    },
    beforeEnter: canAccessMainRoute
  },
  {
    path: '/support',
    name: 'support',
    component: SupportPage,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      showTopNavTabs: true,
      bottomNavigationActive: 'home'
    },
    beforeEnter: canAccessMainRoute
  },
  {
    path: '/offers',
    name: 'offers',
    component: OffersPage,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      showTopNavTabs: true,
      bottomNavigationActive: 'home'
    },
    beforeEnter: canAccessMainRoute
  },
  {
    path: '/smarthome',
    name: 'smarthome',
    component: SmarthomePage,
    meta: {
      bottomNavigation: true,
      topNavigation: true,
      showTopNavTabs: true,
      bottomNavigationActive: 'home'
    },
    beforeEnter: canAccessMainRoute
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter(to, from, next) {
      Vue.prototype.$rhAuth.logout()
      next('login')
    }
  }
]


export default new Router({
  routes: [...defaultRoutes, ...CustomRoutes]
})

function requireAuth(to, from, next) {
  if (!Vue.prototype.$rhAuth.loggedIn()) {
    next({
      path: '/welcome',
      query: {redirect: to.fullPath}
    })
    // leaving authenticating entries in localstorage results in strange behaviour if user with roleId 100 gets
    // redirected to welcome page, therefore preemptively clearing these entries when redirected
    delete localStorage.user
    delete localStorage.token
    delete localStorage.role
    delete localStorage.permissionsId
    delete localStorage.activeSite
  } else {
    next()
  }
}

// check if user is allowed to invite additional user. Checks first if config entry allows invitation,
// if user is NOT allowed to invite additional user, a redirect to /profile happens to prevent accessing the page via url
// on the other hand if user is allowed to invite users, check if user is authenticated (vue-router can't handle two function as navigation
// guards, therefore canInviteAdditionalUser has to call requireAuth to check authentication)
function canInviteAdditionalUser(to, from, next) {
  if (config.canInviteAdditionalUser === 'false') {
    next({
      path: '/profile',
    })
  } else {
    requireAuth(to, from, next)
  }
}

// check if user is allowed to access main (page which can be accessed by topnavigation) page.
function canAccessMainRoute(to, from, next) {
  var topnavArray = config.homeTopNavOrder.split(',')
  if (!(topnavArray.includes(to.name))) {
    next({
      path: '/' + topnavArray[0],
    })
  } else {
    requireAuth(to, from, next)
  }
}
