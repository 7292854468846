<template>
    <v-img contain height="22vh"
           class="ma-auto"
           src="~@/assets/custom/images/login-logo.png"/>
</template>

<script>
export default {
  name: 'LogoNoText'
}
</script>
