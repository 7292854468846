<template>
  <fullscreen-overlay-frame :title="$t('realTimeEnergyPrices.title')"
                            icon="query_stats"
                            color="primary"
                            centered
                            closable
                            @close="close">
    <template v-slot:content>
      <div class="wrapper px-5 py-3">
        <div class="actual">
          <div :class="[getPriceColorClass(current)]">{{ current }} {{ $t('realTimeEnergyPrices.unit') }}</div>
          <div>{{ $t('realTimeEnergyPrices.now') }}</div>
        </div>
        <div :class="['trend', 'pr-10', 'pt-2', getPriceColorClass(next)]">
          <span class="pr-4">{{ diff }} {{ $t('realTimeEnergyPrices.unit') }}</span>
          <v-icon :class="[getPriceColorClass(next)]">
            {{ trending }}
          </v-icon>
        </div>
        <div class="next">
          <div :class="[getPriceColorClass(next)]">{{ next }} {{ $t('realTimeEnergyPrices.unit') }}</div>
          <div>{{ getNextHour().format($t('realTimeEnergyPrices.time')) }}</div>
        </div>
      </div>
      <div class="pb-4">
        <v-btn depressed large :outlined="timescope!=='today'" class="mr-4" color="primary" @click="setTimescope('today')">{{ $t('realTimeEnergyPrices.today') }}</v-btn>
        <v-btn depressed large  :outlined="timescope!=='tomorrow'" color="primary" @click="setTimescope('tomorrow')">{{ $t('realTimeEnergyPrices.tomorrow') }}</v-btn>
      </div>
      <line-chart v-if="hasData"
                  ref="chart"
                 :key="timescope"
                 :options="chartOptions"
          :data="chartData"
      ></line-chart>
      <div class="mt-12 text-center text-h6" v-else>{{ $t('realTimeEnergyPrices.no-data') }}</div>

    </template>
  </fullscreen-overlay-frame>
</template>


<script>
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame.vue";
import moment from 'moment';
import 'chartjs-adapter-moment';
import { Line as LineChart} from 'vue-chartjs'
import energyPriceCalculator from "@/scripts/energyPriceCalculator";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: "realTimeEnergyPricesOverlay",
  components: {FullscreenOverlayFrame, LineChart},
  props: ['data'],
  data: () => ({
    timescope: 'today',
    priceMarker: 26
  }),
  methods: {
    setTimescope(scope) {
      this.timescope = scope
    },

    close() {
      this.$root.bisadialog.toggle('realTimeEnergyPrices')
    },

    getPriceColorClass(price) {
      if (price >= this.priceMarker) {
        return 'price_high'
      } else {
        return 'price_low'
      }
    },

    getCurrentHour() {
      return moment().seconds(0).milliseconds(0).minutes(0)
    },

    getNextHour() {
      return this.getCurrentHour().add(1, 'hours')
    },

    getThemeColor(color) {
      let theme = this.$vuetify.theme.themes.light
      if (this.$vuetify.theme.dark) {
        theme = this.$vuetify.theme.themes.dark
      }

      return theme[color]
    },

    getChartData() {
      let dataset = []
      let labels = []
      let colors = []

      for (let i = 0; i < 24; i++) {
        let m = moment().seconds(0).milliseconds(0).minutes(0).hour(i)
        labels.push(m.format(this.$t('realTimeEnergyPrices.time')))
        if (this.timescope === 'tomorrow') {
          m.add(1, 'day')
        }
        let current = moment().seconds(0).milliseconds(0).minutes(0)

        let price = (this.data[m.unix() * 1000])
        if (typeof price !== 'undefined' && price !== null) {
          price = Math.round(price[1] * 100)
          let endPrice = energyPriceCalculator.calculateEndPrice(price)
          dataset.push(endPrice)
          let transparent = '70';
          if (m.unix() === current.unix()) {
            transparent = 'FF'
          }

          let color
          if (endPrice >= this.priceMarker) {
            color = this.getThemeColor('warning')
          } else {
            color = this.getThemeColor('success')
          }
          colors.push(`${color}${transparent}`)
        }
      }

      return {
        labels: labels,
        datasets: [{
          data: dataset,
          backgroundColor: colors,
          borderColor: (context) => {
            const chart = context.chart;
            const {ctx, chartArea} = chart;

            if (!chartArea) {
              // This case happens on initial chart load
              return;
            }

            let yAxis = chart.scales['y'];
            // Get the y coordinates for the value 0 and for the value when the gradient color should change
            let yBottom = yAxis.getPixelForValue(0);
            let yTop = yAxis.getPixelForValue(this.priceMarker);
            // Create linear gradient
            let gradient = ctx.createLinearGradient(0, yBottom, 0, yTop);
            // Use minimal color gradient with the color stops between 0.98 and 1
            gradient.addColorStop(0.98, this.getThemeColor('success'));
            gradient.addColorStop(1, this.getThemeColor('warning'));

            return gradient;
          },
          borderWidth: 1,
          stepped: true
        }],
      }
    }
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: ((tooltipItems) => {
                return `${tooltipItems.raw} ${this.$t('realTimeEnergyPrices.unit')}`
              })
            }
          }
        },
        scales: {
          y: {
            display: true,
            position: 'right',
            ticks: {
              callback: (val) => {
                return `${val} ${this.$t('realTimeEnergyPrices.unit')}`
              }
            }
          },
        },
      }
    },

    current() {
      return energyPriceCalculator.calculateEndPrice(Math.round(this.data[this.getCurrentHour().unix() * 1000][1] * 100))
    },
    next() {
      return energyPriceCalculator.calculateEndPrice(Math.round(this.data[this.getNextHour().unix() * 1000][1] * 100))
    },
    diff() {
      return this.next - this.current;
    },

    trending() {
      if (this.current < this.next) {
        return 'trending_up'
      } else if (this.current > this.next) {
        return 'trending_down'
      } else {
        return 'trending_flat'
      }
    },

    hasData() {
      return (this.getChartData().datasets[0].data.length > 0)
    },

    chartData() {
      return this.getChartData()
    },
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/real-time-energy-prices.scss';
</style>
