<template>
  <content-card :title="$t('realTimeEnergyPrices.title')"
                icon="query_stats" v-if="show && prices!==null">
    <template v-slot:titleBar>
      <v-icon class="price_info material-icons-outlined" color="primary" @click="openSpotmarketDialog()">
        info
      </v-icon>
    </template>
    <template v-slot:content>
      <div class="wrapper px-5 py-3" @click="openOverlay()">
        <div class="actual">
          <div :class="[getPriceColorClass(current)]">{{ current }} {{ $t('realTimeEnergyPrices.unit') }}</div>
          <div>{{ $t('realTimeEnergyPrices.now') }}</div>
        </div>
        <div :class="['trend', 'pr-10', 'pt-2', getPriceColorClass(next)]">
          <span class="pr-4">{{ diff }} {{ $t('realTimeEnergyPrices.unit') }}</span>
          <v-icon :class="[getPriceColorClass(next)]">
            {{ trending }}
          </v-icon>
        </div>
        <div class="next">
          <div :class="[getPriceColorClass(next)]">{{ next }} {{ $t('realTimeEnergyPrices.unit') }}</div>
          <div>{{ getNextHour().format($t('realTimeEnergyPrices.time')) }}</div>
        </div>
        <v-icon class="price_info" color="primary">
          open_in_full
        </v-icon>
      </div>
    </template>
  </content-card>
</template>


<script>

import config from '@/config/config.app.json'
import ContentCard from "@/templates/components/ContentCard.vue";
import moment from 'moment';
import energyPriceCalculator from "@/scripts/energyPriceCalculator";

export default {
  name: "RealTimeEnergyPrices",
  components: {ContentCard},
  data: () => ({
    prices: null,
    loading: false
  }),
  methods: {
    openSpotmarketDialog() {
      this.$root.bisadialog.toggle('infoText', true, {
        title: this.$t('realTimeEnergyPrices.sportmarket.title'),
        icon: 'query_stats',
        html: this.$t('realTimeEnergyPrices.sportmarket.text')
      })
    },

    openOverlay() {
      this.$root.bisadialog.toggle('realTimeEnergyPrices', true, this.prices)
    },
    getData() {
      this.loading = true
      this.$rhRequest.sendGet({
        endpoint: "spotmarkt",
      }, (response) => {
        this.prices = response.data?.data
        this.loading = false
      }, (err) => {
        console.error(err);
        this.loading = false
      })
    },

    getPriceColorClass(price) {
      if (price >= 26) {
        return 'price_high'
      } else {
        return 'price_low'
      }
    },
    getCurrentHour() {
      return moment().seconds(0).milliseconds(0).minutes(0)
    },
    getNextHour() {
      return this.getCurrentHour().add(1, 'hours')
    }
  },
  mounted() {
    this.getData()
  },
  computed: {
    current() {
      return energyPriceCalculator.calculateEndPrice(Math.round(this.prices[this.getCurrentHour().unix()*1000][1]*100))
    },
    next() {
      return energyPriceCalculator.calculateEndPrice(Math.round(this.prices[this.getNextHour().unix()*1000][1]*100))
    },
    diff() {
      return this.next - this.current;
    },

    show() {
      if (typeof config.hasSpotmarket === 'undefined' ) return false;
      return JSON.parse(config.hasSpotmarket);
    },

    trending() {
      if (this.current < this.next) {
        return 'trending_up'
      } else if (this.current > this.next) {
        return 'trending_down'
      } else {
        return 'trending_flat'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '~@/styles/real-time-energy-prices.scss';
</style>
