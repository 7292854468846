<template>
  <fullscreen-overlay-frame :title="title.toString()"
                            icon="play_circle"
                            color="primary"
                            centered
                            closable
                            :key="key"
                            @close="close">
    <template v-slot:content>
      <v-alert v-if="data?.scene?.id && data?.scene?.attributes?.synced === 'FAILED'"
               text
               class="font-size-03"
               icon="sync_problem"
               border="left"
               type="warning">
        <div v-text="$t('add-rule-dialog.not-synced')"/>
        <v-btn outlined small
               color="warning"
               class="mt-3 float-right"
               :loading="syncing"
               @click="syncScene">
          {{ $t('add-rule-dialog.not-synced.button') }}
        </v-btn>
      </v-alert>

      <v-stepper flat
                 v-model="step">
        <v-stepper-items class="overflow-visible">
          <add-scene-step-overview v-model="sceneProps"
                                   ref="sceneOverview"
                                   :actions="actions"
                                   :actionSubjects="deviceSelections"
                                   :loading-actions="loadingActions"
                                   @add-action="addAction"
                                   @edit-action="editAction"
                                   @remove-action="removeAction"/>

          <!-- action type selection -->
          <add-rule-step-action-type ref="actionSelection"
                                     v-model="actionType"
                                     type="scene"/>
          <!-- device action -->
          <add-rule-step-device-selection ref="deviceSelection"
                                          v-model="lastDeviceSelection"
                                          :excluded-items="deviceSelections"/>
          <!-- special case: ir-controller :/ -->
          <add-rule-step-i-r-controller-action-definition
              v-if="lastDeviceSelection?.device?.type === 'infrared-controller'"
              ref="setPointDefinition"
              v-model="lastEditedAction"
              :item="lastDeviceSelection"/>
          <!-- every other device type -->
          <add-rule-step-set-point-definition v-else
                                              ref="setPointDefinition"
                                              v-model="lastEditedAction"
                                              :item="lastDeviceSelection"/>
          <!-- scene action -->
          <add-rule-step-scene-selection ref="sceneSelection"
                                         v-model="lastEditedAction"
                                         :excluded-items="assignedSceneRefs"/>
          <!-- notification action -->
          <add-rule-step-notification-type-selection ref="notificationTypeSelection"
                                                     v-model="lastNotificationTypeSelection"/>
          <add-rule-step-notification-definition ref="notificationDefinition"
                                                 v-model="lastEditedAction"
                                                 :type="lastNotificationTypeSelection"/>
        </v-stepper-items>
      </v-stepper>
    </template>

    <template v-slot:actions>
      <!-- next button -->
      <v-btn v-if="step > 1 && step <= 4  || step >= 10 && step <= 15"
             large depressed
             :disabled="buttonDisabled"
             color="primary"
             class="font-weight-bold action-button"
             @click="nextButtonClick">
        {{ nextButtonLabel }}
      </v-btn>

      <!-- back button -->
      <v-btn v-if="step >= 2 && step < 5 || step >= 10"
             large depressed outlined
             color="primary"
             class="mt-4 ml-0 action-button"
             @click="previousStep">
        {{ $t('app.step-back') }}
      </v-btn>

      <!-- save button -->
      <v-btn v-if="step === 1"
             :disabled="actions.length < 1 || loading || !isSiteAdmin"
             :loading="loading"
             large depressed
             color="primary"
             class="font-weight-bold action-button"
             @click="save">
        <v-icon left>add</v-icon>
        {{ $t('add-scene-dialog.save') }}
      </v-btn>

      <!-- delete button -->
      <v-btn v-if="step === 1 && data?.scene"
             :disabled="loading || !isSiteAdmin"
             large outlined depressed
             color="primary"
             class="mt-4 ml-0 font-weight-bold action-button"
             @click="deleteScene">
        <v-icon left>delete</v-icon>
        {{ $t('add-scene-dialog.delete') }}
      </v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import AddRuleStepActionType from "@/templates/dialogs/automations/AddRuleStepActionType.vue";
import AddRuleStepDeviceSelection from "@/templates/dialogs/automations/AddRuleStepDeviceSelection.vue";
import AddRuleStepSetPointDefinition from "@/templates/dialogs/automations/AddRuleStepSetPointDefinition.vue";
import AddSceneStepOverview from "@/templates/dialogs/automations/AddSceneStepOverview.vue";
import AddRuleStepSceneSelection from "@/templates/dialogs/automations/AddRuleStepSceneSelection.vue";
import automations, {actionTypes} from "@/scripts/automations";
import AddRuleStepIRControllerActionDefinition
  from "@/templates/dialogs/automations/specialCases/AddRuleStepIRControllerActionDefinition.vue";
import AddRuleStepNotificationDefinition from "@/templates/dialogs/automations/AddRuleStepNotificationDefinition.vue";
import AddRuleStepNotificationTypeSelection
  from "@/templates/dialogs/automations/AddRuleStepNotificationTypeSelection.vue";

export default {
  name: 'AddSceneDialog',
  components: {
    AddRuleStepNotificationTypeSelection,
    AddRuleStepNotificationDefinition,
    AddRuleStepIRControllerActionDefinition,
    AddRuleStepSceneSelection,
    AddSceneStepOverview,
    AddRuleStepSetPointDefinition,
    AddRuleStepDeviceSelection,
    AddRuleStepActionType,
    FullscreenOverlayFrame,
  },

  props: ['data'],

  data: function () {
    return {
      /**
       * Step IDs:
       * 1 - overview

       * 10 - action type selection
       * 11 - device selection
       * 12 - set point definition
       * 13 - scene selection
       * 14 - notification type selection
       * 15 - notification definition
       */
      step: 1,
      title: this.$t('add-scene-dialog.overview.title'),
      sceneProps: {
        name: this.data?.rule?.attributes?.name ?? this.$t('add-rule-dialog.new-scene.default-name'),
        iconKey: this.data?.rule?.attributes?.iconKey ?? 'play_circle',
        delay: this.data?.rule?.attributes?.delay ?? null
      },
      actionType: automations.actionTypes.devices, // preselection
      lastDeviceSelection: null,
      deviceSelections: [],
      lastEditedAction: null,
      actions: [],
      loading: false,
      sceneId: null, // only set when editing an existing scene
      loadingActions: false,
      syncing: false,
      /**
       * when currently editing an action this holds the index of the action which is being edited; null otherwise
       */
      editingActionItemIdx: null,
      lastNotificationTypeSelection: null,
      key: 100
    }
  },

  computed: {
    nextButtonLabel() {
      if (this.step === 12
          || this.step === 13
          || this.step === 15
          || (this.step === 14 && this.lastNotificationTypeSelection === 'PUSH')) {
        return this.$t('add-rule-dialog.add-action')
      } else if (this.step > 1 && this.step < 4 || this.step >= 10 && this.step < 15) {
        return this.$t('app.continue')
      }
      return ""
    },

    buttonDisabled() {
      switch (this.step) {
        case 11:
          return !this.$refs.deviceSelection?.valid
        case 12:
          return !this.$refs.setPointDefinition?.valid
        case 13:
          return !this.$refs.sceneSelection?.valid
        case 14:
          return !this.$refs.notificationTypeSelection?.valid
        case 15:
          return !this.$refs.notificationDefinition?.valid
        default:
          return false
      }
    },

    isSiteAdmin() {
      return this.$rhAuth.isSiteAdmin()
    },

    /**
     * list of scenes which are already selected as action including the currently edited scene (self)
     *
     * @returns {*[]}
     */
    assignedSceneRefs() {
      // dereference array as we don't want to alter this.actions
      let excludedSceneActions = [...this.actions]

      // when currently editing an existing scene, the user should not be able to add this scene to the actions
      if (this.sceneId) {
        let self = {
          sceneRef: {
            id: this.sceneId
          },
          type: actionTypes.sceneRefs
        }
        excludedSceneActions.push(self)
      }
      return excludedSceneActions
    }
  },

  methods: {
    /**
     * Initializes the dialog with an existing scene. Used for editing a scene, so only call
     * init() if the user wants to edit an existing scene.
     */
    init() {
      this.sceneId = this.data.scene.id
      this.sceneProps.name = this.data.scene.attributes.name
      this.sceneProps.delay = this.data.scene.attributes.delay
      this.sceneProps.iconKey = this.data.scene.attributes.iconKey
      // init scene overview
      this.$refs.sceneOverview.init()

      if (this.data.scene.attributes.actions) {
        this.data.scene.attributes.actions.map((action) => {
          if (action.type === 'sceneRefs') {
            this.getSceneName(action)
          }
        })

        this.actions = this.data.scene.attributes.actions
        this.$refs.sceneOverview.updateData(this.actions)
        // get device selections for all actions
        let deviceIds = []
        this.actions.forEach((action) => {
          if (action.deviceId) {
            deviceIds.push(action.deviceId)
          }
        })
        this.getDevices(deviceIds)
      } // add cases for other action types here as soon as we support them
    },

    getSceneName(action) {
      this.loading = true
      this.loadingActions = true
      this.$rhRequest.sendGet({
        endpoint: 'scenes/get?id=' + action.sceneRef
      }, (response) => {
        this.loading = false
        this.loadingActions = false
        action.name = response?.data?.data?.attributes?.name
        this.actions = this.data.scene.attributes.actions
        this.$refs.sceneOverview.updateData(this.actions)
        this.key++
      }, (error) => {
        console.error(error)
        this.loading = false
        this.loadingActions = false
      })
    },

    /**
     * action for the next button
     */
    nextButtonClick() {
      if (this.step === 10 && this.actionType === automations.actionTypes.sceneRefs) {
        this.goToSceneSelection()
      } else if (this.step === 10 && this.actionType === automations.actionTypes.notifications) {
        this.goToNotificationTypeSelection()
      } else if (this.step === 12 && this.editingActionItemIdx == null) {
        // user added a new device action
        this.actions.push(this.lastEditedAction)
        this.deviceSelections.push(this.lastDeviceSelection)
        this.goToOverview()
      } else if (this.step === 12 && this.editingActionItemIdx != null) {
        // user edited an existing action: replace the old action by the newly edited one
        this.actions[this.editingActionItemIdx] = this.lastEditedAction;
        this.deviceSelections[this.editingActionItemIdx] = this.lastDeviceSelection;
        this.$refs.sceneOverview.updateData(this.actions, this.deviceSelections)
        // reset edit action indication as editing is done now
        this.editingActionItemIdx = null
        this.goToOverview()
      } else if ((this.step === 13 || this.step === 15)
          && this.editingActionItemIdx == null) {
        // user added a new scene action
        this.actions.push(this.lastEditedAction)
        this.goToOverview()
      } else if ((this.step === 13 || this.step === 15)
          && this.editingActionItemIdx != null) {
        // user edited an existing action: replace the old action by the newly edited one
        this.actions[this.editingActionItemIdx] = this.lastEditedAction
        this.$refs.sceneOverview.updateData(this.actions)
        // reset edit action indication as editing is done now
        this.editingActionItemIdx = null
        this.goToOverview()
      } else if (this.step === 14 && this.lastNotificationTypeSelection === 'PUSH' && this.editingActionItemIdx == null) {
        // user added a new action of type notifications:push
        this.lastEditedAction = {
          type: actionTypes.notifications,
          notificationType: 'PUSH'
        }
        this.actions.push(this.lastEditedAction)
        this.goToOverview()
      } else if (this.step === 14 && this.lastNotificationTypeSelection === 'PUSH' && this.editingActionItemIdx != null) {
        // user edited an existing action: replace the old action by the newly edited one of type notifications:push
        this.lastEditedAction = {
          type: actionTypes.notifications,
          notificationType: 'PUSH'
        }
        this.actions[this.editingActionItemIdx] = this.lastEditedAction
        this.$refs.sceneOverview.updateData(this.actions)
        // reset edit action indication as editing is done now
        this.editingActionItemIdx = null
        this.goToOverview()
      } else {
        this.nextStep()
      }
    },

    /**
     * loads the overview step
     */
    goToOverview() {
      this.step = 1
    },

    /**
     * loads the scene selection step
     */
    goToSceneSelection() {
      this.step = 13
    },

    /**
     * loads the notification type selection step
     */
    goToNotificationTypeSelection() {
      this.step = 14
    },

    /**
     * proceeds to action selection
     */
    addAction() {
      // reset last edited action data
      this.actionType = automations.actionTypes.devices
      this.$refs.actionSelection.setActionType(this.actionType)
      this.lastEditedAction = null
      this.lastDeviceSelection = null
      this.$refs.deviceSelection.init()
      this.$refs.sceneSelection.init()
      this.lastNotificationTypeSelection = null
      this.$refs.notificationTypeSelection.init()
      this.$refs.notificationDefinition.init()
      this.step = 10
    },

    /**
     * loads an action and goes to the edit action step
     */
    editAction(actionIdx) {
      // init action data for editing
      this.lastEditedAction = this.actions[actionIdx]

      if (this.lastEditedAction.type === automations.actionTypes.devices) {
        // pre-select action type
        this.actionType = automations.actionTypes.devices
        this.$refs.actionSelection.setActionType(this.actionType)
        // init device action item selection for editing
        this.lastDeviceSelection = this.deviceSelections[actionIdx]
        this.$refs.deviceSelection.init(this.lastDeviceSelection)
        if (this.lastEditedAction.setPoint) {
          // set set-point to last value
          this.$refs.setPointDefinition.setSetPoint(this.lastEditedAction.setPoint)
        } else if (this.lastEditedAction.switchOperation) {
          // set switch operation to last value
          this.$refs.setPointDefinition.setSwitchOperation(this.lastEditedAction.switchOperation)
        }
      } else if (this.lastEditedAction.type === automations.actionTypes.sceneRefs) {
        // pre-select action type
        this.actionType = automations.actionTypes.sceneRefs
        this.$refs.actionSelection.setActionType(this.actionType)
        // pre-select last scene
        this.$refs.sceneSelection.init(this.lastEditedAction)
      } else if (this.lastEditedAction.type === automations.actionTypes.notifications) {
        // pre-select action type
        this.actionType = automations.actionTypes.notifications
        this.$refs.actionSelection.setActionType(this.actionType)
        // pre-select last notification type and definition
        this.$refs.notificationTypeSelection.init(this.lastEditedAction.notificationType)
        this.$refs.notificationDefinition.init(this.lastEditedAction)
      } // add other action types here if necessary

      this.editingActionItemIdx = actionIdx
      this.step = 10
    },

    /**
     * removes an action from the action list
     */
    removeAction(actionIdx) {
      if (this.actions[actionIdx].type === actionTypes.devices) {

        let deviceSelectionIdx = this.deviceSelections.findIndex(item => item.device.id === this.actions[actionIdx].deviceId)
        this.deviceSelections.splice(deviceSelectionIdx, 1)
      }
      this.actions.splice(actionIdx, 1)
    },

    /**
     * proceeds to next step
     */
    nextStep() {
      this.step++
    },

    /**
     * back to previous step
     */
    previousStep() {
      if (this.step === 10 || this.step === 13) {
        this.goToOverview()
      } else if (this.step === 14) {
        this.step = 10
      } else {
        this.step--
      }
    },

    /**
     * saves a scene
     */
    save() {
      this.loading = true

      this.actions.map((action) => {
        if (action.type === "sceneRefs") {
          delete action.name
          delete action.id
        }
      });

      let scene = {
        // clientId and siteId will be added by the proxy (PHP)
        name: this.sceneProps.name,
        delay: this.sceneProps.delay,
        iconKey: this.sceneProps.iconKey,
        actions: {...this.actions}, // clone the actions
        synced: 'PENDING'
      }

      if (this.sceneId) {
        // update existing scene
        this.$rhRequest.sendPost(
            {
              endpoint: "scenes/update",
              data: scene,
              params: {id: this.sceneId}
            },
            (resp) => this.sceneRequestSuccessCallback(resp, this.$t('add-rule-dialog.save.rule-updated', {name: this.sceneProps.name})),
            (err) => {
              console.error(err)
              this.loading = false
              if (err.response.data.code === 580) {
                this.$root.bisatoast.warning({
                  message: this.$t('add-rule-dialog.save.gateway-offline'),
                  showCloseBtn: true
                })
                this.$root.bisadialog.toggle('addScene')
              } else {
                this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
              }
            })
      } else {
        // create new scene
        this.$rhRequest.sendPost(
            {
              endpoint: "scenes/add",
              data: scene
            },
            (resp) => this.sceneRequestSuccessCallback(resp, this.$t('add-rule-dialog.save.rule-created', {name: this.sceneProps.name})),
            (err) => {
              console.error(err)
              this.loading = false
              if (err.response.data.code === 580) {
                this.$root.bisatoast.warning({
                  message: this.$t('add-rule-dialog.save.gateway-offline'),
                  showCloseBtn: true
                })
                this.$root.bisadialog.toggle('addScene')
              } else {
                this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
              }
            }
        )
      }
    },

    /**
     * method to call when creating or updating a scene was successful
     * @param response
     * @param successMessage
     */
    sceneRequestSuccessCallback(response, successMessage) {
      if (response.data.code === 0) {
        this.$root.bisatoast.success({
          message: successMessage,
          showCloseBtn: true
        })
        // notifies ScenesCard to reload the scene items
        this.$root.$emit('reloadItems')
        this.$root.bisadialog.toggle('addScene')
      } else {
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
      }
      this.loading = false
    },

    /**
     * gets a single device actuator item by device id
     * @param {Array} deviceIds
     */
    getDevices(deviceIds) {
      if (deviceIds.length === 0) {
        // no actions with type=devices
        return
      }

      this.loadingActions = true
      this.$rhRequest.sendGet({
        endpoint: "rule/device-actuator-items",
        params: {deviceId: deviceIds}
      }, (resp) => {
        this.deviceSelections = Object.values(resp?.data?.data)
        this.$refs.sceneOverview.updateData(this.actions, this.deviceSelections)
        this.loadingActions = false
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
        this.loadingActions = false
      })
    },

    /**
     * synchronises a scene with the Gateway
     */
    syncScene() {
      this.syncing = true
      this.$rhRequest.sendGet({
        endpoint: "scenes/sync-scene",
        params: {id: this.sceneId}
      }, () => {
        this.$root.bisatoast.success({message: this.$t('add-rule-dialog.sync-rule.success'), showCloseBtn: true})
        this.syncing = false
        this.save()
      }, (err) => {
        console.error(err)
        this.$root.bisatoast.error({message: this.$t('add-rule-dialog.sync-rule.error'), showCloseBtn: true})
        this.syncing = false
      })
    },

    /**
     * deletes a scene
     */
    deleteScene() {
      if (!this.isSiteAdmin) {
        return
      }
      let scene = this.data?.scene
      this.$root.bisadialog.toggle('confirmation', true, {
        maxWidth: 400,
        title: this.$t('confirmation-dialog.title'),
        text: this.$t('add-scene-dialog.delete-scene.confirm', {name: this.data.scene.attributes.name}),
        confirmLabel: this.$t('add-scene-dialog.delete')
      })
      this.$root.$on('dialogConfirmed', () => {
        this.$rhRequest.sendDelete({
          endpoint: 'scenes/delete',
          params: {id: scene.id}
        }, () => {
          this.$root.bisatoast.success({
            message: this.$t('add-scene-dialog.delete-rule.success', {name: scene.attributes.name}),
            showCloseBtn: true
          })
          // notifies ScenesCard to reload the scene items
          this.$root.$emit('reloadItems')
        }, (error) => {
          console.error(error)
          if (error.response.data.code === 580) {
            this.$root.bisatoast.warning({message: this.$t('add-rule-dialog.save.gateway-offline'), showCloseBtn: true})
            this.$root.bisadialog.toggle('addScene')
          } else {
            this.$root.bisatoast.error({message: this.$t('app.generic-error'), showCloseBtn: true})
          }
        })
        this.$root.$off('dialogConfirmed')
      })
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('addScene')
    },
  },

  watch: {
    /**
     * updates the dialog title
     *
     * @param value
     */
    step(value) {
      switch (value) {
        case 1:
          this.title = this.$t('add-scene-dialog.overview.title')
          break
        case 10:
        case 11:
        case 12:
        case 13:
        case 14:
        case 15:
          this.title = this.$t('add-rule-dialog.action.title')
          break
        default:
          this.title = this.$t('add-scene-dialog.overview.title')
          break
      }
    }
  }
};
</script>
